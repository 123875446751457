import React from "react"

import { GatsbySeo } from "gatsby-plugin-next-seo"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Header1 from "../components/header1"
import GoogleMap from "../components/google-map"

import "../styles/about.css"

export default function About() {

  const data = useStaticQuery(graphql`
  query {
    avatarMalte: file(relativePath: { eq: "avatar_malte.png" }) {
      childImageSharp {
        fixed(width: 184, height: 184, toFormat: JPG) {
          ...GatsbyImageSharpFixed
        }
      }
    },
    avatarAlex: file(relativePath: { eq: "avatar_alex.jpg" }) {
      childImageSharp {
        fixed(width: 184, height: 184, toFormat: JPG) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return (
    <Layout fullWave="false" bottomWave="false" callToAction="true">
      <GatsbySeo description="Our team is our unfair advantage" />

      <section className="bg-white pt-20">
        <div className="container max-w-5xl mx-auto m-8">
          <Header1 headerText="About LinkFive" />
        </div>

        <div className="max-w-5xl mx-auto mt-8 text-gray-500 md:text-xl">
          <p className="p-3">
            LinkFive is the brainchild of{" "}
            <a
              href="https://www.linkedin.com/in/athiele/"
              target="_blank"
              className="text-l5-secondary"
              rel="noreferrer"
            >
              Alexander Thiele
            </a>{" "}
            and{" "}
            <a
              href="https://www.linkedin.com/in/mschiebelmann/"
              target="_blank"
              className="text-l5-secondary"
              rel="noreferrer"
            >
              Malte Schiebelmann
            </a>
            . As veterans of the european consumer start-up ecosystem they
            experienced first hand, what it means to run successful subscription
            businesses - and how hard it is. Armed with experience from
            companies like Doodle, Prosperity and Zattoo they decided to address
            the problem head on and LinkFive was born.
          </p>
        </div>
      </section>

      <section className="bg-white">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12 max-w-5xl text-center">
          <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
            <Img
              fixed={data.avatarAlex.childImageSharp.fixed}
              className="inline object-cover w-48 h-48 rounded-full mx-auto border-4 border-l5-highlight border-opacity-100"
              alt="Avatar Alex"
            />
            <div className="w-full font-bold text-xl text-gray-800 px-6 mt-5 highlight">
              Alexander Thiele
            </div>
            <p className="text-gray-800 text-base px-6">Co-Founder, Tech</p>
            <p className="text-gray-500 text-base px-6 mt-3 mb-5">
              Alex is a seasoned tech leader with a wide range of experience
              from being CTO of a fintech to launching a wide series of apps at
              Doodle as well as TNX.
            </p>
          </div>
          <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
            <Img
              fixed={data.avatarMalte.childImageSharp.fixed}
              className="inline object-cover w-48 h-48 rounded-full mx-auto border-4 border-l5-highlight border-opacity-100"
              alt="Avatar Malte"
            />
            <div className="w-full font-bold text-xl text-gray-800 px-6 mt-5 highlight">
              Malte Schiebelmann
            </div>
            <p className="text-gray-800 text-base px-6">Co-Founder, Product</p>
            <p className="text-gray-500 text-base px-6 mt-3 mb-5">
              Malte has always had a passion for great product - and was
              involved in creating some heavily used ones as CPO at Doodle and
              Head of Product at Zattoo.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container max-w-5xl mx-auto p-6">
          <Header1 headerText="Our Mission" />
        </div>

        <div className="max-w-5xl mx-auto text-gray-500 md:text-xl pb-12">
          <blockquote className="relative p-4 text-2xl italic bg-neutral-100 text-neutral-600 border-neutral-500 quote">
            <div className="stylistic-quote-mark" aria-hidden="true">
              &ldquo;
            </div>
            <p className="mb-4">
              We enable everyone to be successful with their app subscription
              businesses. We take care of the hard parts so that you don't have
              to. A solution to your problem is only one click away.{" "}
              <strong>No coding</strong> is required to{" "}
              <strong>
                build, measure, learn and optimize your subscriptions
              </strong>{" "}
              - just like you do with other parts of your app - so that you can
              focus on what you do best: Build great applications.
            </p>
          </blockquote>
        </div>
      </section>

      <section className="bg-white">
        <Header1 headerText="Made in Germany" />
        <div className="p-5"></div>
        <GoogleMap />
      </section>
    </Layout>
  )
}
